<template>
  <v-form ref="UserForm" @submit.prevent="handleSave">
    <v-container class="form__container pt-6" fluid>
      <v-row>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="form.name"
            :disabled="isLoading"
            :label="$trans('name')"
            :rules="[rules.required, rules.maxChars(50)]"
            outlined
          />
        </v-col>

        <v-col cols="12" md="4">
          <v-text-field
            v-model="form.surname"
            :disabled="isLoading"
            :label="$trans('surname')"
            :rules="[rules.required, rules.maxChars(50)]"
            outlined
          />
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="form.email"
            :disabled="isLoading"
            :label="$trans('email')"
            :rules="[rules.required, rules.email, rules.maxChars(60)]"
            validate-on-blur
            outlined
          />
        </v-col>
        <v-col class="pt-0" cols="12" md="4">
          <phone-input
            v-model="form.default_phone"
            :disabled="isLoading"
            :rules="loggedUserIsAdmin ? [rules.required] : []"
            :default-country-code="$config('preferred_phone_countries')[0]"
            @enter="handleSave"
          />
        </v-col>
      </v-row>
      <template
        v-if="
          $adminConfig('app_employees_time_zone_enabled') ||
          $adminConfig('app_employees_locale_enabled') ||
          form.locale ||
          form.time_zone
        "
      >
        <v-row>
          <v-col>
            <h6 class="text-h6" v-text="$trans('user_time_zone_title')" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <calendesk-information-message>
              {{ $trans("user_time_zone_locale_info") }}
            </calendesk-information-message>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <c-language-select
              v-model="form.locale"
              :label="$trans('c_language_select_label')"
              :disabled="
                isLoading || !$adminConfig('app_employees_locale_enabled')
              "
            />
          </v-col>
          <v-col cols="12" md="6">
            <c-time-zone-autocomplete
              v-model="form.time_zone"
              :label="$trans('c_time_zone_autocomplete_label')"
              :disabled="
                isLoading || !$adminConfig('app_employees_time_zone_enabled')
              "
            />
          </v-col>
        </v-row>
      </template>
    </v-container>
    <v-container fluid class="form__container">
      <v-row justify="start">
        <v-col>
          <v-btn
            :disabled="isLoading"
            :loading="isLoading"
            color="primary"
            type="submit"
          >
            <span v-text="$trans('save')" />
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-divider class="my-4" />
    <v-container fluid class="form__container">
      <v-row>
        <v-col>
          <h6 class="text-h6" v-text="$trans('reset_password')" />
        </v-col>
      </v-row>
      <v-row justify="start">
        <v-col>
          <v-btn
            :disabled="isResetingPassword"
            :loading="isResetingPassword"
            color="blue"
            outlined
            @click="handleResetPassword"
          >
            <span v-text="$trans('forgotten_password_button')" />
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>
<script>
import { mapActions, mapMutations } from "vuex";
import {
  errorNotification,
  successNotification,
} from "@/lib/calendesk-js-library/tools/notification";
import {
  email,
  maxChars,
  minChars,
  required,
} from "@/lib/calendesk-js-library/forms/validators";
import PhoneInput from "@/components/Forms/PhoneInput/PhoneInput.vue";
import { pushEvent } from "@/lib/calendesk-js-library/tools/helpers";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";
import CalendeskInformationMessage from "@/lib/calendesk-js-library/components/CalendeskInformationMessage.vue";
import CTimeZoneAutocomplete from "@/views/dashboard/pages/Settings/components/CTimeZoneAutocomplete.vue";
import CLanguageSelect from "@/views/dashboard/pages/Settings/components/CLanguageSelect.vue";

export default {
  name: "YourProfileForm",
  components: {
    CLanguageSelect,
    CTimeZoneAutocomplete,
    CalendeskInformationMessage,
    PhoneInput,
  },
  mixins: [sharedActions],
  data() {
    return {
      isValidPhone: true,
      isLoading: false,
      isResetingPassword: false,
      rules: {
        required,
        email,
        minChars,
        maxChars,
      },
      form: {
        name: null,
        surname: null,
        email: null,
        default_phone: null,
        time_zone: null,
        locale: null,
      },
    };
  },
  watch: {
    loggedUser(val) {
      if (val !== null) {
        this.fillForm();
      }
    },
  },
  created() {
    this.$nextTick(() => {
      this.fillForm();
    });
  },
  methods: {
    ...mapActions({
      updateOwnUser: "user/updateOwnUser",
      getUserData: "auth/getUserData",
      shouldRedirectAndFixUrl: "setup/shouldRedirectAndFixUrl",
    }),
    ...mapMutations({
      setUser: "auth/SET_USER",
    }),
    fillForm() {
      this.form.name = this.loggedUser.name;
      this.form.surname = this.loggedUser.surname;
      this.form.email = this.loggedUser.email;
      this.form.default_phone = this.loggedUser.default_phone
        ? this.loggedUser.default_phone.e164
        : null;
      this.form.time_zone = this.loggedUser.time_zone;
      this.form.locale = this.loggedUser.locale;
    },
    handleSave() {
      if (this.$refs.UserForm.validate()) {
        this.update();
      } else {
        errorNotification("form_is_invalid", {}, false);
      }
    },
    async update() {
      this.isLoading = true;

      pushEvent("updateOwnProfile");

      const oldLocale = this.loggedUser.locale;
      const oldTimezone = this.loggedUser.time_zone;

      this.updateOwnUser(this.form)
        .then(async (response) => {
          this.setUser(response);
          successNotification("update_success");
          this.isLoading = false;

          if (oldLocale !== this.loggedUser.locale) {
            await this.shouldRedirectAndFixUrl(this.loggedUser.locale);
          } else if (oldTimezone !== this.loggedUser.time_zone) {
            location.reload();
          }
        })
        .catch((error) => {
          this.showErrorMessage(error);
          this.isLoading = false;
        });
    },
    showErrorMessage(error) {
      let errorMessage = this.$trans("error_occurred");
      const emailIsTakenError =
        this.$helpers.emailIsTakenError(error) || error.response.status === 409;

      if (emailIsTakenError) {
        errorMessage = this.$trans("email_is_taken");
      }
      errorNotification(errorMessage, error, !emailIsTakenError);
    },
    handleResetPassword() {
      this.isResetingPassword = true;

      pushEvent("resetOwnPassword");

      this.updateOwnUser({
        in_admin: 1,
        send_reset_password: 1,
      })
        .then((response) => {
          this.setUser(response);
          successNotification("reset_password_sent_email");
        })
        .catch((error) => {
          errorNotification(null, error);
        })
        .finally(() => {
          this.isResetingPassword = false;
        });
    },
  },
};
</script>
<style lang="scss">
.checkbox-align .v-input__slot {
  align-items: start;
}
</style>
