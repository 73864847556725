<template>
  <v-select
    v-model="language"
    outlined
    :items="items"
    :label="label"
    :disabled="disabled"
    hide-details="auto"
    :prepend-icon="hideIcon ? null : '$translate'"
    :dense="dense"
    @input="inputEventHandle"
  />
</template>

<script>
import { trans } from "@/lib/calendesk-js-library/prototypes/trans";

export default {
  name: "CLanguageSelect",
  props: {
    value: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: trans("c_language_select_label"),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    hideIcon: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      items: [
        {
          text: this.$trans("c_language_select_polish"),
          value: "pl",
        },
        {
          text: this.$trans("c_language_select_english"),
          value: "en",
        },
        {
          text: this.$trans("c_language_select_spanish"),
          value: "es",
        },
        {
          text: this.$trans("c_language_select_german"),
          value: "de",
        },
      ],
      language: this.value,
    };
  },
  watch: {
    value() {
      this.language = this.value;
    },
  },
  methods: {
    inputEventHandle(language) {
      this.$emit("input", language);
    },
  },
};
</script>
